




































































































































import { Component, Prop } from "vue-property-decorator";
import { age, date, time } from "@/filters/date.filter";
import { Getter } from "vuex-class";
import { Patient } from "@/modules/patient/types/patient.type";
import moment from "moment";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import PatientTags from "../PatientTags.vue";
import PatientProfilePicture from "./PatientProfilePicture.vue";
import ContactInformationDialog from "../ContactInformationDialog/ContactInformationDialog.vue";
import { Contact } from "@/modules/contacts/types/contact.type";


@Component({
  components: {
    PatientTags,
    PatientProfilePicture,
    ContactInformationDialog
  },
  filters: {
    date,
    time,
    age
  }
})
export default class PatientInfo extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  @Getter("auth/isRtl") isRtl: boolean;
  @Getter("auth/isEditEnabled") isEditEnabled: boolean;
  @Getter("auth/isPatientProfilePictureEnabled") isPatientProfilePictureEnabled: boolean;
  public contactInformation: Contact = null

  public sendWhatsapp(phone: string) {
    this.shareWhatsapp(phone, "");
  }

  public openContactInformationModal(referredByInformation: Contact) {
    this.contactInformation = referredByInformation;
  }

  public validPhone(phone: string) {
    if (!phone) {
      return false;
    }

    const reformatedPhone = phone.replace("-", "");

    return reformatedPhone.length >= 9 && reformatedPhone.length <= 10;
  }

  public onUploadProfilePicture(formData: FormData) {
    this.$emit("uploadProfilePicture", formData);
  }

  public onRemoveProfilePicture() {
    this.$emit("removeProfilePicture");
  }

  get gender() {
    return this.patient.gender ?
      this.$t(`genders.${this.patient.gender}`)
      : "";
  }

  get patientAddress() {
    return this.patient.address ? this.patient.address : null;
  }

  get patientZipCode() {
    return this.patient.zip_code ? this.patient.zip_code : null;
  }

  get patientCity() {
    return this.patient.city ? this.patient.city : null;
  }

  get updatedAt() {
    return moment(this.patient.updated_at).format("DD-MM-YYYY");
  }

  get createdAt() {
    return moment(this.patient.created_at).format("DD-MM-YYYY");
  }

  get birthDate() {
    return moment(this.patient.birth_date).format("DD-MM-YYYY");
  }

  get homePhone() {
    return (this.patient.attributes && this.patient.attributes.home_phone ? this.patient.attributes.home_phone : "");
  }

  get homePhoneNote() {
    return (this.patient.attributes && this.patient.attributes.home_phone_note ? this.patient.attributes.home_phone_note : "");
  }

  get contact1Phone() {
    return (this.patient.contact1_phone ? this.patient.contact1_phone : "");
  }

  get contact1Name() {
    return (this.patient.contact1_name ? this.patient.contact1_name : "");
  }

  get contact1Email() {
    return (this.patient.contact1_email ? this.patient.contact1_email : "");
  }

  get contact2Phone() {
    return (this.patient.contact2_phone ? this.patient.contact2_phone : "");
  }

  get contact2Name() {
    return (this.patient.contact2_name ? this.patient.contact2_name : "");
  }

  get contact2Email() {
    return (this.patient.contact2_email ? this.patient.contact2_email : "");
  }
}
