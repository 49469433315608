

















import { Component, Prop } from "vue-property-decorator";
import SmileMixin from "@/mixins/smile.mixin";
import mixins from "vue-class-component";

@Component({
})
export default class PatientProfileImageDialog extends mixins(SmileMixin) {
  @Prop({ required: true }) public imageUrl: string;

  public closeDialog() {
    this.$emit("close");
  }

}
