import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-image-wrapper"},[_c(VImg,{key:'profile-' + _vm.patient.profile_path,staticClass:"grey elevation-3 pointer",attrs:{"max-height":"150","min-height":"50","width":"105","src":_vm.patient.profile_temporary_url ? _vm.patient.profile_temporary_url : require('@/assets/no-profile-picture.png'),"contain":""},on:{"click":function($event){return _vm.onProfileImageClicked()}}}),_c('input',{ref:"uploadInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/png, image/jpeg, image/jpg;capture=camera","name":"file"},on:{"change":function($event){return _vm.uploadFiles($event.target.files)}}}),(_vm.patient.profile_temporary_url)?_c(VMenu,{attrs:{"offset-y":"","nudge-left":"7"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"background-color":"rgba(70, 70, 70, 0.5)"},attrs:{"icon":"","color":"white","small":""}},'VBtn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-pencil-circle-outline")])],1)]}}],null,false,2812251502)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.uploadFileClicked()}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('upload')))]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-upload")])],1)],1),(!_vm.isMobile)?_c(VListItem,{on:{"click":function($event){_vm.displayTakeNewPictureDialog = true}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('capture')))]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-camera-plus-outline")])],1)],1):_vm._e(),_c(VListItem,{on:{"click":function($event){return _vm.onDeleteProfilePictureClicked()}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('delete')))]),_c(VListItemAction,[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)],1):_vm._e(),_c(VMenu,{attrs:{"offset-y":"","nudge-top":"90"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VCard,_vm._g(_vm._b({},'VCard',attrs,false),on))]}}]),model:{value:(_vm.displayTakeNewPictureMenu),callback:function ($$v) {_vm.displayTakeNewPictureMenu=$$v},expression:"displayTakeNewPictureMenu"}},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.uploadFileClicked()}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('upload_new')))]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-upload")])],1)],1),_c(VListItem,{on:{"click":function($event){_vm.displayTakeNewPictureDialog = true}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.$t('capture_new')))]),_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-camera-plus-outline")])],1)],1)],1)],1),(_vm.displayProfileImageDialog)?_c('PatientProfileImageDialog',{attrs:{"imageUrl":_vm.patient.profile_temporary_url},on:{"close":function($event){_vm.displayProfileImageDialog = false}}}):_vm._e(),(_vm.displayTakeNewPictureDialog)?_c('PatientTakeNewProfileImageDialog',{on:{"close":function($event){_vm.displayTakeNewPictureDialog = false},"onUploadNewImage":_vm.uploadNewImage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }