import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":true,"max-width":600},on:{"input":function($event){return _vm.closeDialog()}}},[_c(VCard,{staticClass:"patient-profle-image-dialog",attrs:{"outlined":"","raised":"","elevation":"4"}},[_c(VCardTitle,{staticClass:"justify-space-between"},[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"d-flex justify-center"},[_c(VImg,{staticStyle:{"border":"1px solid #aaa"},attrs:{"contain":"","src":_vm.imageUrl,"min-height":"100","max-height":"450","max-width":"450"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }