




























































import { Component, Prop } from "vue-property-decorator";
import { Patient } from "@/modules/patient/types/patient.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import PatientProfileImageDialog from "./PatientProfileImageDialog.vue";
import PatientTakeNewProfileImageDialog from "./PatientTakeNewProfileImageDialog.vue";

@Component({
  components: {
    PatientProfileImageDialog,
    PatientTakeNewProfileImageDialog
  },
})
export default class PatientProfilePicture extends mixins(SmileMixin) {
  @Prop({ required: true }) public patient: Patient;
  $refs!: {
    uploadInput: HTMLFormElement;
  }
  public displayProfileImageDialog = false;
  public displayTakeNewPictureMenu = false;
  public displayTakeNewPictureDialog = false;

  public uploadFileClicked() {
    this.$refs.uploadInput.click();
  }

  public showFileClicked() {
    this.displayProfileImageDialog = true;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly;
  }

  public onProfileImageClicked() {
    if (this.patient.profile_temporary_url) {
      return this.showFileClicked();
    } else {
      return this.isMobile ? this.uploadFileClicked() : this.displayTakeNewPictureMenu = true;
    }
  }

  public async uploadFiles(fileList) {
    if (!fileList.length) {
      return;
    }

    const formData = new FormData();
    formData.append("file", fileList[0], fileList[0].name);
    formData.append("patient_id", this.patient.id.toString());

    this.$emit("uploadProfilePicture", formData);
    if (this.$refs && this.$refs.uploadInput && this.$refs.uploadInput.value) {
      this.$refs.uploadInput.value = "";
    }
  }

  public async uploadNewImage(file) {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("patient_id", this.patient.id.toString());
    this.$emit("uploadProfilePicture", formData);
    this.displayTakeNewPictureDialog = false;
  }

  public async onDeleteProfilePictureClicked() {
    this.$emit("removeProfilePicture");
  }

}
